import { useCallback, useEffect } from 'react'
import { useRouter } from 'next/compat/router'
import { mergeRight, not, omit, path, pipe, whereEq } from 'ramda'
import { VARIANTS } from '@/api/tracking'
import { useDispatch, useSelector } from 'react-redux'

import { getSearch, pushSearch } from '@/utils/location'
import { initVariants } from '@/components/ab-test'

const DEFAULT_SORT_VARIANTS = [
  {
    name: 'newest',
    probability: 0.5
  },
  {
    name: 'suggested',
    probability: 0.5
  }
]

const VARIANT_MAP = {
  newest: { sort_by: 'CREATED_AT', sort_type: 'DESC' },
  suggested: { sort_by: 'SUGGESTED', sort_type: 'ASC' }
}

export const EMPTY_FILTERS = {
  housing_types: [],
  unit_types: [],
  min_price: null,
  max_price: null,
  pet_policy: [],
  bedroom_count: [],
  bathroom_count: [],
  availability_date: null,
  custom_availability_date: null,
  sort: null,
  search_only_verified: null,
  min_size: null,
  lease_type: null,
  rental_type: [],
  has_3d_tour: null,
  features: [],
  utilities: []
}

export const useFilters = () => {
  const filters = useSelector(path(['listings', 'filters']))
  const router = useRouter()
  const setFilter = useCallback(
    newFilter => {
      pushSearch(router, newFilter)
    },
    [router]
  )

  const clearFilters = () => {
    setFilter(EMPTY_FILTERS)
  }

  const isFilterSet = pipe(
    omit(['cities', 'featured', 'page', 'page_size', 'neighborhoods']),
    mergeRight(EMPTY_FILTERS),
    whereEq(EMPTY_FILTERS),
    not
  )(filters)

  return [filters, setFilter, clearFilters, isFilterSet]
}

export const useFiltersListener = ({ enableSortVariants = true } = {}) => {
  const dispatch = useDispatch()
  const router = useRouter()

  useEffect(() => {
    const fetchSearch = async () => {
      try {
        const search = await getSearch(router)
        if (enableSortVariants) {
          if (!search.sort) {
            const variant = initVariants(VARIANTS.LISTING_SORT, {
              variants: DEFAULT_SORT_VARIANTS,
              cookieConfig: {
                path: '/',
                maxAge: 30 * 24 * 60 * 60 // 30 days
              }
            })
            search.sort = VARIANT_MAP[variant]
          }
        }
        dispatch.listings.updateFilter(search)
      } catch (error) {
        console.error('Error in getSearch:', error)
      }
    }

    fetchSearch()
  }, [dispatch.listings, router, enableSortVariants])
}
