import { useMutation, type UseMutationOptions } from '@tanstack/react-query'

import config from '@/config'
import { post } from '@/ports/api'
import { useFilters } from '@/hooks/use-filters'
import { getVariant } from '@/components/ab-test'

type Data = {
  metadata: any
  event_name: string
  variant_name?: string | null
  variant_group?: string | null
  referer?: string | null
}

const trackEvent = post(`${config.BACKEND_SERVER}/ab/l`)
async function track(data: Data) {
  if (data.variant_group) {
    data.variant_name = getVariant(data.variant_group)
  }
  return trackEvent({ body: data })
}

export function useTracking(
  rest: UseMutationOptions<any, any, Omit<Data, 'variant_name'>>
) {
  return useMutation({
    retry: 3,
    ...rest,
    mutationFn: data => track(data)
  })
}

export function useTrackingWithSort() {
  const stored =
    typeof localStorage !== 'undefined' &&
    localStorage.getItem('livrent:filters')
  const filters = stored ? (JSON.parse(stored) as any) : {}

  return useTracking({
    onMutate: data => {
      if (filters.sort) {
        data.metadata.sort = filters.sort
      }
      return data
    }
  })
}

export const EVENTS = {
  PUBLIC_CHAT_STARTED: 'public-chat-started',
  INQUIRY_SENT: 'inquiry-sent',
  APPLICATION_SUBMITTED: 'application-submitted'
}

export const VARIANTS = {
  LISTING_SORT: 'listing-default-sort'
}
