import Cookies from 'universal-cookie'

const cookies = new Cookies()

export type Variant = {
  name: string
  probability: number
}

export type CookieConfig = {
  path?: string
  maxAge?: number
  prefix?: string
}

export type Config = {
  variants: Variant[]
  cookieConfig?: CookieConfig
}

export function initVariants(name: string, config: Config) {
  const total = config.variants.reduce((a, b) => a + b.probability, 0)
  if (total !== 1) {
    throw new Error('Probability sum should be 1')
  }

  const COOKIE_PREFIX = config?.cookieConfig?.prefix || 'ab_test_'
  const COOKIE_NAME = `${COOKIE_PREFIX}${name}`

  let variant = cookies.get(COOKIE_NAME)
  if (!variant) {
    const randomValue = Math.random()
    let cumulativeProbability = 0

    for (const variantInTest of config.variants) {
      cumulativeProbability += variantInTest.probability
      if (randomValue < cumulativeProbability) {
        variant = variantInTest.name
        cookies.set(COOKIE_NAME, variant, {
          path: config?.cookieConfig?.path,
          maxAge: config?.cookieConfig?.maxAge
        })
        break
      }
    }
  }
  return variant
}

export function getVariant(name: string, config?: Config) {
  const COOKIE_PREFIX = config?.cookieConfig?.prefix || 'ab_test_'
  const COOKIE_NAME = `${COOKIE_PREFIX}${name}`
  return cookies.get(COOKIE_NAME)
}
