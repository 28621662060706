import styled from 'styled-components'

type LabelTypes = {
  large: string
  medium: string
  small: string
}

type Size = '10px' | '12px' | '14px' | '16px' | '18px'

type LabelProps = {
  type?: keyof LabelTypes
  fontSize?: Size
}

const label: Record<Size, LabelTypes> = {
  '18px': {
    large: '24px',
    medium: '24px',
    small: '14px'
  },
  '16px': {
    large: '20px',
    medium: '20px',
    small: '16px'
  },
  '14px': {
    large: '16px',
    medium: '16px',
    small: '14px'
  },
  '12px': {
    large: '12px',
    medium: '12px',
    small: '12px'
  },
  '10px': {
    large: '12px',
    medium: '12px',
    small: '12px'
  }
}

const getHeight = (props: LabelProps) => {
  const size = props.fontSize ?? '16px'
  const weight = props.type ?? 'medium'
  return label[size][weight]
}

const getWeight = (props: LabelProps) => {
  const weight = props.type ?? 'medium'
  if (weight === 'large') return 700
  if (weight === 'medium') return 500
  return 400
}

export const Label = styled.div.withConfig<LabelProps>({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['fontSize', 'type'].includes(prop) && defaultValidatorFn(prop)
})`
  font-family: Inter, sans-serif;
  font-size: ${props => props.fontSize || '16px'};
  line-height: ${props => getHeight(props)};
  font-weight: ${props => getWeight(props)};
`
